import React from 'react';
import { Route, Routes } from 'react-router-dom';
import routes from './routes';
import './App.css';

const App = () => {
  return (
    <Routes>
      {routes.map(({ path, element }, index) => (
        <Route key={index} path={path} element={element} />
      ))}
    </Routes>
  );
};

export default App;
