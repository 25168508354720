import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const Logout = () => {
  useEffect(() => {
    // Remove the token from cookies
    Cookies.remove('token', {
      path: '/',                // Ensure the path matches
      domain: '.ditinerary.com', // Set the domain if it was specified
      secure: true,             // Include secure flag if the cookie was set with it
      sameSite: 'None',         // Match the SameSite attribute
    });

    // Remove the token and user from localStorage
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  }, []);

  // Redirect to the login page after logout
  return <Navigate to="/" />;
};

export default Logout;
