import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { dbFQDN, basePath, appVersion } from "../config";
import "./Category.css";

const Category = ({ user: initialUser = "Guest" }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [user, setUser] = useState(initialUser);
  const [itemsData, setItemsData] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [keywordFilter, setKeywordFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [destination, setDestination] = useState("wdw"); // Default destination
  const [activeKeywords, setActiveKeywords] = useState([]);
  const [filteredKeywords, setFilteredKeywords] = useState([]);
  const [loadedItems, setLoadedItems] = useState({});
  const itemRefs = useRef({}); // Store refs for items
  const localStorageKey = `itemData_${appVersion}`;

  const categoryData = useMemo(
    () => [
      { id: "park", name: "Park" },
      { id: "ride", name: "Rides" },
      { id: "dining", name: "Dining" },
      { id: "resort", name: "Resort" },
      { id: "travel", name: "Travel" },
      { id: "extra", name: "Extras" },
    ],
    []
  );

  // Extract the current category ID from the URL
  const currentPath = location.pathname.replace("/", ""); // Remove leading "/"
  const id = categoryData.find((category) => category.id === currentPath)?.id; 
  
  // Dynamically update filteredKeywords based on activeKeywords
  const handleKeywordFilter = (event) => {
    const value = event.target.value.trim().toLowerCase();
    setKeywordFilter(value);
  
    if (value) {
      // Dynamically filter keywords based on input
      const updatedFilteredKeywords = keywords.filter(
        ({ keyword }) =>
          keyword.toLowerCase().startsWith(value) && !activeKeywords.includes(keyword)
      );
  
      setFilteredKeywords(updatedFilteredKeywords);
    } else {
      // Reset to the default keyword set when input is empty
      setFilteredKeywords(
        keywords.filter(
          ({ keyword, count }) =>
            !activeKeywords.includes(keyword.toLowerCase()) && count !== filteredResults.length
        )
      );
    }
  };  
  
  const handleKeywordSave = (event) => {
    if (event.key === "," || event.key === "Enter") {
      const trimmedKeyword = keywordFilter.trim().replace(/,$/, "").toLowerCase();
  
      if (trimmedKeyword && !activeKeywords.includes(trimmedKeyword)) {
        const newActiveKeywords = [...activeKeywords, trimmedKeyword];
        setActiveKeywords(newActiveKeywords);
  
        // Update filteredResults based on new activeKeywords
        const filtered = filterItemsByAllKeywords(itemsData[id], newActiveKeywords);
        setFilteredResults(filtered);
  
        // Update filteredKeywords from the filtered dataset
        const updatedFilteredKeywords = filtered
          .flatMap((item) => (item.keywords || "").split(",").map((kw) => kw.trim()))
          .filter(
            (kw) =>
              !newActiveKeywords.includes(kw.toLowerCase()) &&
              keywords.some(({ keyword }) => keyword === kw)
          );
  
        setFilteredKeywords(
          [...new Set(updatedFilteredKeywords)].map((kw) => ({
            keyword: kw,
            count: filtered.filter((item) => item.keywords?.includes(kw)).length,
          }))
        );
      }
  
      // Clear the input after saving the keyword
      setKeywordFilter("");
    }
  };  

  const handleRemoveKeyword = (keywordToRemove) => {
    const newActiveKeywords = activeKeywords.filter(
      (keyword) => keyword !== keywordToRemove
    );
    setActiveKeywords(newActiveKeywords);
  
    // Update filteredResults based on updated activeKeywords
    const filtered = filterItemsByAllKeywords(itemsData[id], newActiveKeywords);
    setFilteredResults(filtered);
  
    // Refresh filteredKeywords
    const updatedFilteredKeywords = filtered
      .flatMap((item) => {
        // Normalize `item.keywords` to an array
        const keywords = Array.isArray(item.keywords)
          ? item.keywords
          : typeof item.keywords === "string"
          ? item.keywords.split(",").map((kw) => kw.trim())
          : [];
        return keywords;
      })
      .filter(
        (kw) =>
          !newActiveKeywords.includes(kw.toLowerCase()) &&
          keywords.some(({ keyword }) => keyword === kw)
      );
  
    setFilteredKeywords(
      [...new Set(updatedFilteredKeywords)].map((kw) => ({
        keyword: kw,
        count: filtered.filter((item) =>
          Array.isArray(item.keywords) && item.keywords.includes(kw)
        ).length,
      }))
    );
  };  
  
  const handleAddKeyword = (keyword) => {
    if (!activeKeywords.includes(keyword)) {
      const newActiveKeywords = [...activeKeywords, keyword.toLowerCase()];
      setActiveKeywords(newActiveKeywords);
  
      // Update filteredResults
      const filtered = filterItemsByAllKeywords(itemsData[id], newActiveKeywords);
      setFilteredResults(filtered);
  
      // Refresh filteredKeywords
      const updatedFilteredKeywords = filtered
        .flatMap((item) => {
          // Ensure `item.keywords` is normalized to an array
          const keywords = Array.isArray(item.keywords)
            ? item.keywords
            : typeof item.keywords === "string"
            ? item.keywords.split(",").map((kw) => kw.trim())
            : [];
          return keywords;
        })
        .filter(
          (kw) =>
            !newActiveKeywords.includes(kw.toLowerCase()) &&
            keywords.some(({ keyword }) => keyword === kw)
        );
  
      setFilteredKeywords(
        [...new Set(updatedFilteredKeywords)].map((kw) => ({
          keyword: kw,
          count: filtered.filter((item) =>
            Array.isArray(item.keywords) && item.keywords.includes(kw)
          ).length,
        }))
      );
  
      // Clear the search bar after adding the keyword
      setKeywordFilter("");
    }
  };  

  const handleCatSelect = (categoryID, catItem) =>
    navigate(`/${categoryID}/${catItem}`);
  const handleHome = () => navigate("/");

  const handleDestinationChange = (e) => {
    setDestination(e.target.value);
    console.log(`Selected destination: ${e.target.value}`);
  };

  const handleCategorySelect = useCallback(
    (parentId) => {
      navigate(`/${parentId}`);
      return;
    },
    [navigate]
  );

  // Outside of the component
  const filterItemsByAllKeywords = (data, activeKeywords) => {
    if (!data) return [];
  
    // If no active keywords, return all items
    if (activeKeywords.length === 0) {
      return data;
    }
  
    return data.filter((item) => {
      // Normalize `item.keywords` to an array
      const keywordsArray = Array.isArray(item.keywords)
        ? item.keywords.map((keyword) => keyword.toLowerCase().trim())
        : typeof item.keywords === "string"
        ? item.keywords.split(",").map((keyword) => keyword.toLowerCase().trim())
        : [];
  
      // Check if all activeKeywords are included in the item's keywords
      return activeKeywords.every((activeKeyword) =>
        keywordsArray.includes(activeKeyword.toLowerCase().trim())
      );
    });
  };
  
  const updateFilters = useCallback(
    (data) => {
      const filteredResults = filterItemsByAllKeywords(data, activeKeywords);
      setFilteredResults(filteredResults);

      // Generate keywords from filteredResults
      const keywordCounts = {};
      filteredResults.forEach((item) => {
        const keywords = Array.isArray(item.keywords)
          ? item.keywords
          : typeof item.keywords === "string"
          ? item.keywords.split(",").map((kw) => kw.trim())
          : [];
        keywords.forEach((keyword) => {
          if (keyword) {
            keywordCounts[keyword.toLowerCase()] =
              (keywordCounts[keyword.toLowerCase()] || 0) + 1;
          }
        });
      });

      // Sort and filter keywords
      const sortedKeywords = Object.entries(keywordCounts)
        .map(([keyword, count]) => ({ keyword, count }))
        .filter(
          ({ keyword, count }) =>
            !activeKeywords.includes(keyword) && count !== filteredResults.length
        )
        .sort((a, b) => b.count - a.count);

      setKeywords(sortedKeywords); // Update `keywords`
      setFilteredKeywords(sortedKeywords); // Update `filteredKeywords`
    },
    [activeKeywords]
  );
  
  // IntersectionObserver for lazy loading images
  useEffect(() => {
    const inViewStates = {}; // Track which items are in view
    const observers = Object.keys(itemRefs.current).map((itemId) => {
      const ref = itemRefs.current[itemId]?.current; // Access the DOM element using `current`
  
      if (ref) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting && !inViewStates[itemId]) {
              inViewStates[itemId] = true;
              setLoadedItems((prev) => ({ ...prev, [itemId]: true })); // Mark item as loaded
              observer.unobserve(entry.target); // Stop observing once item is loaded
            }
          },
          { threshold: 0.1, root: null }
        );
  
        observer.observe(ref);
        return observer;
      }
      return null;
    });
  
    return () => observers.forEach((observer) => observer && observer.disconnect());
  }, [filteredResults]);  

  useEffect(() => {
    // Check if user is stored in localStorage
    const localStorageUser = JSON.parse(localStorage.getItem("user")) || null;
    if (localStorageUser?.name) {
      setUser(localStorageUser.name); // Assign the user's name to `user` state
    }
  
    if (itemsData[id]) {
      updateFilters(itemsData[id]);
    } else {
      const storedData = JSON.parse(localStorage.getItem(localStorageKey)) || {};
      if (storedData[id]) {
        setItemsData((prevItemsData) => ({ ...prevItemsData, [id]: storedData[id] }));
        updateFilters(storedData[id]);
      } else {
        setIsLoading(true);
        fetch(`${dbFQDN}/di/v1/${id}`)
          .then((response) => response.json())
          .then((data) => {
            const transformedData = data.map((item) => ({
              ...item,
              // Ensure keywords are a string or empty string
              keywords: Array.isArray(item.data?.keywords)
                ? item.data.keywords.join(",") // Join array into a string
                : typeof item.data?.keywords === "string"
                ? item.data.keywords
                : "", // Fallback to an empty string
            }));
  
            setItemsData((prev) => ({ ...prev, [id]: transformedData }));
            localStorage.setItem(
              localStorageKey,
              JSON.stringify({ ...storedData, [id]: transformedData })
            );
  
            setActiveKeywords([]);
            updateFilters(transformedData);
          })
          .catch((error) => console.error("Error fetching data:", error))
          .finally(() => setIsLoading(false));
      }
    }
  }, [id, itemsData, localStorageKey, updateFilters]);

  return (
    <div className="category-layout">
      {/* Left Sidebar */}
      <div className="sidebar">
        <button className="sidebar-home-button" onClick={handleHome}>
          Home
        </button>
        <div className="sidebar-user-greeting">Hi, {user}</div>
        <div className="sidebar-destination">
          <label htmlFor="destination-select">Destination:</label>
          <select
            id="destination-select"
            value={destination}
            onChange={handleDestinationChange}
          >
            <option value="wdw">Walt Disney World</option>
            <option value="dlr">Disneyland Resort</option>
            <option value="uo">Universal Orlando</option>
          </select>
        </div>
        {categoryData.map((parent) => (
          <button
            key={parent.id}
            className={`sidebar-item ${
              id === parent.id ? "selected" : ""
            }`}
            onClick={() => handleCategorySelect(parent.id)}
          >
            {parent.name}
          </button>
        ))}
      </div>

      {/* Middle Bar */}
      <div className="filter-bar">
        <input
          type="text"
          placeholder="Search or Add Keywords"
          className="filter-input"
          value={keywordFilter}
          onChange={handleKeywordFilter}
          onKeyDown={handleKeywordSave} // Listen for 'comma' key to save keyword
        />
        <div className="active-keywords">
          {activeKeywords.map((activeKeyword, index) => (
            <div key={index} className="active-keyword">
              {activeKeyword}
              <button
                className="remove-keyword"
                onClick={() => handleRemoveKeyword(activeKeyword)}
              >
                x
              </button>
            </div>
          ))}
        </div>
        <div className="keyword-list">
          {filteredKeywords.map(({ keyword, count }) => (
            <div
              key={keyword}
              className="keyword-item"
              onClick={() => handleAddKeyword(keyword)} // Add keyword on click
            >
              {keyword}
            </div>
          ))}
        </div>
      </div>

      {/* Main Content */}
      <div className="main-content">
        {isLoading ? (
          <p className="loading-text">Loading...</p>
        ) : (
          filteredResults.map((item) => {
            if (!itemRefs.current[item.id]) {
              itemRefs.current[item.id] = React.createRef();
            }

            return (
              <div
                key={item.id}
                className="content-card"
                ref={itemRefs.current[item.id]}
                onClick={() => handleCatSelect(id, item.id)}
              >
                <div className="poster">
                  {/* Only render the image if the item is loaded */}
                  {loadedItems[item.id] && (
                    <img
                      src={`/c/${basePath}/${id}/${item.id || "default"}-360x360.webp`}
                      alt={item.comment || `${id} slot image`}
                      className="poster-image"
                    />
                  )}
                  <div className="poster-overlay">
                    <h3 className="poster-title">{item.name}</h3>
                    <p className="poster-description">{item.comment}</p>
                    <div className="poster-learn-more">
                      <button>Learn More</button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>

    </div>
  );
};

export default Category;
