// routes.js
import Itinerary from './components/Itinerary';
import GettingStarted from './components/GettingStarted';
import AdultsChildren from './components/AdultsChildren';
import CheckInCheckOut from './components/CheckInCheckOut';
import SelectResort from './components/SelectResort';
import SelectRoom from './components/SelectRoom';
import ReviewAndSave from './components/ReviewAndSave';
import Login from './components/Login';
import About from './components/About';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import Logout from './components/Logout';
import SubscriptionPage from './components/SubscriptionPage';
import Category from './components/Category';
import CategoryItem from './components/CategoryItem';
import Socialshare from './components/Socialshare';

const routes = [
  { path: '/login', element: <Login /> },
  { path: '/logout', element: <Logout /> },
  { path: '/about', element: <About /> },
  { path: '/privacy-policy', element: <PrivacyPolicy /> },
  { path: '/terms-and-conditions', element: <TermsAndConditions /> },
  { path: '/subscription', element: <SubscriptionPage /> },
  { path: '/itinerary/:itinerary_id', element: <Itinerary /> },
  { path: '/itinerary', element: <Itinerary /> },
  { path: '/:itinerary_id', element: <Itinerary /> },
  { path: '/', element: <Itinerary /> },
  { path: '/getting-started', element: <GettingStarted /> },
  { path: '/adults-children', element: <AdultsChildren /> },
  { path: '/check-in-check-out', element: <CheckInCheckOut /> },
  { path: '/select-resort', element: <SelectResort /> },
  { path: '/select-room', element: <SelectRoom /> },
  { path: '/review-and-save', element: <ReviewAndSave /> },
  { path: '/park/:id', element: <CategoryItem /> },
  { path: '/ride/:id', element: <CategoryItem /> },
  { path: '/dining/:id', element: <CategoryItem /> },
  { path: '/resort/:id', element: <CategoryItem /> },
  { path: '/travel/:id', element: <CategoryItem /> },
  { path: '/extra/:id', element: <CategoryItem /> },
  { path: '/park', element: <Category /> },
  { path: '/ride', element: <Category /> },
  { path: '/dining', element: <Category /> },
  { path: '/resort', element: <Category /> },
  { path: '/travel', element: <Category /> },
  { path: '/extra', element: <Category /> },
  { path: '/assets/images/social-share-image-2-360x360.webp', element: <Socialshare /> },
];
  
// Export for ES Modules (React)
export default routes;